.welcome-page {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &__content {
        height: max-content;
        max-width: 80%;

        &__buttons {
            display: flex;
            justify-content: center;

            &__button {
                margin: 1rem 0.5rem;
            }
        }
    }
}
