@import 'variables.scss';

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.standard-page-wrapper {
    padding: 2rem;
    overflow: hidden;
    max-width: $standard-form-width;
    margin: 0 auto;
}

.large-page-wrapper {
    @extend .standard-page-wrapper;
    max-width: 80%;
}
