@import '/src/variables.scss';

.login {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__box {
        padding: 2rem;
        overflow: hidden;
        width: $standard-form-width;
        margin: 0 auto;

        &__form {
            margin-top: 2rem;
        }
    }
}
