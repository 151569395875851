@media (max-width: 768px) {
    .welcome-page {
		overflow: auto;
	
		&__content {
			&__buttons {
				display: flex;
				flex-direction: column;
				justify-content: center;
	
				&__button {
					margin: 0.5rem 0;
				}
			}
		}
	}
	
}