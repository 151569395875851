.cards-wrapper {
    display: flex;
    flex-direction: row;

    &__card {
        width: 320px;
        overflow: hidden;
        margin: 1rem;

        &__illustration {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 50%;
            top: 2rem;
            opacity: 0.2;
        }
    }
}
