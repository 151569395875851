@media (max-width: 768px) {
    .cards-wrapper {
        flex-direction: column;
		margin-top: 10rem;

		&__card {
			margin: 1rem 0;
			width: 100%;
		}
    }
}